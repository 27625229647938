export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"de","name":"DE","file":"index.js","fullName":"German","native":"Deutsch","iso":"de-DE"},{"code":"en","name":"EN","file":"index.js","fullName":"English","native":"English","iso":"en-GB"},{"code":"es","name":"ES","file":"index.js","fullName":"Spanish","native":"Español","iso":"es-ES"},{"code":"ja","name":"JA","file":"index.js","fullName":"Japanese","native":"日本語","iso":"ja-JP"},{"code":"lt","name":"LT","file":"index.js","fullName":"Lithuanian","native":"Lietuvių","iso":"lt-LT"},{"code":"ua","name":"UA","file":"index.js","fullName":"Ukrainian","native":"Українська","iso":"uk-UA"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/src/locales",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "https://teltonika-networks.com",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  fallbackLocale: "en",
  skipNuxtState: false,
  normalizedLocales: [{"code":"de","name":"DE","file":"index.js","fullName":"German","native":"Deutsch","iso":"de-DE"},{"code":"en","name":"EN","file":"index.js","fullName":"English","native":"English","iso":"en-GB"},{"code":"es","name":"ES","file":"index.js","fullName":"Spanish","native":"Español","iso":"es-ES"},{"code":"ja","name":"JA","file":"index.js","fullName":"Japanese","native":"日本語","iso":"ja-JP"},{"code":"lt","name":"LT","file":"index.js","fullName":"Lithuanian","native":"Lietuvių","iso":"lt-LT"},{"code":"ua","name":"UA","file":"index.js","fullName":"Ukrainian","native":"Українська","iso":"uk-UA"}],
  localeCodes: ["de","en","es","ja","lt","ua"],
}

export const localeMessages = {
  'index.js': () => import('../../locales/index.js' /* webpackChunkName: "lang-index.js" */),
}
