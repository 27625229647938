
import { isPaginationUpdate } from '~/utils'
export default {
  name: 'ContactUsButton',
  data() {
    return {
      show: false,
    }
  },
  computed: {
    isJapanLanguage() {
      return this.$i18n.locale === 'ja'
    },
    ctaText() {
      return (this.$config.isTelematics || this.$config.isNetworks) &&
        this.$route.meta?.paths?.en?.startsWith('/products')
        ? this.$i18n.t('front.get_quotation')
        : this.$i18n.t('front.contact_us')
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        if (isPaginationUpdate(to, from)) return
        this.show = false
        setTimeout(() => (this.show = true), 1200)
      },
    },
  },
}
