/**
 * date-fns follows Unicode CLDR
 * list of codes: https://www.unicode.org/cldr/cldr-aux/charts/28/summary/ru.html
 */
const getUnicodeCLDR = (code) => {
  switch (code) {
    case 'ua':
      return 'uk'
    case 'dk':
      return 'da'
    case 'no':
      return 'nb'
    case 'se':
      return 'sv'
    case 'at':
      return 'de_AT'
    case 'ja':
      return 'ja'
    default:
      return code
  }
}

export default {
  async mounted() {
    const { locale } = this.$i18n
    if (locale === 'en') return
    try {
      const languageCode = getUnicodeCLDR(locale)
      const module = await import(`date-fns/locale/${languageCode}/index.js`)
      this.$store.commit('date-fns/setLocale', module.default)
    } catch (error) {
      this.$sentry.captureException('ERROR in dateFnsLocale.js file:', error)
    }
  },
}
